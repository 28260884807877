import about from "../assets/images/IMG_7065.jpg"
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

function Section2() {
  return (
    <>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={12} xl={6} sm={12} md={6} lg={6} sx={{ padding: "5%"}}>
          <Typography
            variant="h2"
            textAlign="center"
            sx={{
              fontFamily: "Love Light",
              textTransform: "capitalize",
            }}
          >
            About the couple
          </Typography>
          <Typography
            variant="h6"
            textAlign="center"
            paragraph
            sx={{
              fontFamily: "Lovelace",
            }}
          >
            Having been together for the past 12 years and with one-year engagement, we’ve decided to make it official this October in an intimate celebration, surrounded by dear family and close friends.
          </Typography>
        </Grid>
        <Grid item xs={12} xl={6} sm={12} md={6} lg={6}>
          <Box
            component="img"
            sx={{
              width: "100%",
            }}
            src={about}
          >
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default Section2;
