import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useEffect, useState } from "react";

const weddingDate = new Date("October 8, 2023 12:00:00").getTime();

function Section3() {
  // state to store the time remaining until the wedding date
  const [timeRemaining, setTimeRemaining] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  // function to update the time remaining until the wedding date
  const updateTimeRemaining = () => {
    const now = new Date().getTime();
    const timeLeft = weddingDate - now;
    setTimeRemaining({
      days: Math.floor(timeLeft / (1000 * 60 * 60 * 24)),
      hours: Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      minutes: Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.floor((timeLeft % (1000 * 60)) / 1000),
    });
  };

  useEffect(() => {
    // update the time remaining every second
    const timer = setInterval(updateTimeRemaining, 1000);
    return () => clearInterval(timer);
  }, []);

  // grid layout with 4 boxes of equal size using material ui
  return (
    <>
      <Grid container
        sx={{
          minHeight: {
            xs: "300px",
            sm: "300px",
            md: "500px",
          },
        }}
      >
        <Grid item xs={12} sm={12} md={8} mdOffset={2}
          sx={{
            background: "rgba(253, 160, 160, 0.85);",
            marginTop: "5%",
            marginBottom: "5%",
            position: "relative",
          }}
        >
          <Typography
            variant="h2"
            textAlign="center"
            sx={{
              fontFamily: "Lovelace",
              textTransform: "lowercase",
              position: "absolute",
              top: "20%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              color: "#FFFFFF",
            }}
          >
            Save the date!
          </Typography>
          <Box
            sx={{
              position: "absolute",
              top: "60%",
              left: "20%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Typography
              variant="h4"
              textAlign="center"
              sx={{
                fontFamily: "Lovelace",
                textTransform: "lowercase",
                color: "#FFFFFF",
              }}
            >
              {timeRemaining.days}<br />days
            </Typography>
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: "60%",
              left: "40%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Typography
              variant="h4"
              textAlign="center"
              sx={{
                fontFamily: "Lovelace",
                textTransform: "lowercase",
                color: "#FFFFFF",
              }}
            >
              {timeRemaining.hours}<br />hours
            </Typography>
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: "60%",
              left: "60%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Typography
              variant="h4"
              textAlign="center"
              sx={{
                fontFamily: "Lovelace",
                textTransform: "lowercase",
                color: "#FFFFFF",
              }}
            >
              {timeRemaining.minutes}<br />mins
            </Typography>
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: "60%",
              left: "80%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Typography
              variant="h4"
              textAlign="center"
              sx={{
                fontFamily: "Lovelace",
                textTransform: "lowercase",
                color: "#FFFFFF",
              }}
            >
              {timeRemaining.seconds}<br />secs
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default Section3;
