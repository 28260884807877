import restaurant from "../assets/images/restaurant.png"
import { Box, Link, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

function Section4() {
  const onLinkClick = (e) => {
    e.preventDefault();
    if (window.analytics) {
      window.analytics.track("venue_link.click");
    }
    window.open("https://goo.gl/maps/dLMdQe6NpDoJFswU6", "_blank", "noopener noreferrer");
  };

  return (
    <>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
          <Typography
            variant="h4"
            textAlign="center"
            sx={{
              fontFamily: "Lovelace",
              textTransform: "capitalize",
            }}
          >
            Event
          </Typography>
        </Grid>
        <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
          <Typography
            variant="h2"
            textAlign="center"
            sx={{
              fontFamily: "Lovelace",
              textTransform: "capitalize",
              color: "#fda0a0",
            }}
          >
            Wedding Information
          </Typography>
        </Grid>
        <Grid item xs={12} xl={6} sm={12} md={6} lg={6}>
          <Box
            component="img"
            sx={{
              width: "100%",
            }}
            src={restaurant}
          >
          </Box>
        </Grid>
        <Grid item xs={12} xl={6} sm={12} md={6} lg={6} sx={{ padding: "5%"}}>
          <Typography
            variant="h4"
            textAlign="center"
            sx={{
              fontFamily: "Lovelace",
              textTransform: "capitalize",
              color: "#fda0a0",
            }}
          >
            Luncheon Reception
          </Typography>
          <br />
          <Typography
            variant="h5"
            textAlign="center"
            paragraph
            sx={{
              fontFamily: "Lovelace",
              textTransform: "capitalize",
              color: "#fda0a0",
            }}
          >
            Date:
          </Typography>
          <Typography
            variant="h6"
            textAlign="center"
            paragraph
            sx={{
              fontFamily: "Lovelace",
              textTransform: "capitalize",
              fontWeight: "bold",
            }}
          >
            Sunday, 8th October 2023
          </Typography>
          <Typography
            variant="h5"
            textAlign="center"
            paragraph
            sx={{
              fontFamily: "Lovelace",
              textTransform: "capitalize",
              color: "#fda0a0",
            }}
          >
            Time:
          </Typography>
          <Typography
            variant="h6"
            textAlign="center"
            paragraph
            sx={{
              fontFamily: "Lovelace",
              textTransform: "capitalize",
              fontWeight: "bold",
            }}
          >
            12:00pm
          </Typography>
          <Typography
            variant="h5"
            textAlign="center"
            paragraph
            sx={{
              fontFamily: "Lovelace",
              textTransform: "capitalize",
              color: "#fda0a0",
            }}
          >
            Venue:
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Link variant="h6" textAlign="center" paragraph sx={{
              fontFamily: "Lovelace",
              textTransform: "capitalize",
              fontWeight: "bold",
            }} component="button" underline="hover" onClick={onLinkClick}>
              📍 Lucky Palace Restaurant, Seremban 2
            </Link>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default Section4;
