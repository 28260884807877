import { Link, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

function Footer() {
  // generate simple footer using material ui with text created with love by Wilson
  const love = String.fromCodePoint(0x1F49C);

  const onKeenHoongClick = (e) => {
    e.preventDefault();
    if (window.analytics) {
      window.analytics.track("instagram_link.click", {
        title: "Keen Hoong",
      });
    }
    window.open("https://www.instagram.com/wilson_hoonghoong", "_blank", "noopener noreferrer");
  };

  const onChianLihClick = (e) => {
    e.preventDefault();
    if (window.analytics) {
      window.analytics.track("instagram_link.click", {
        title: "Chian Lih",
      });
    }
    window.open("https://www.instagram.com/cheriechilili", "_blank", "noopener noreferrer");
  };

  return (
    <>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={12} xl={12} sm={12} md={12} lg={12}>
          <Typography
            variant="inherit"
            textAlign="center"
            sx={{
              marginTop: "5%",
              marginBottom: "5%",
              fontFamily: "Lovelace",
              textTransform: "capitalize",
            }}
          >
            Created with {love}
            <br />
            by <Link component="button" underline="hover" onClick={onKeenHoongClick}>Keen Hoong</Link> & <Link component="button" underline="hover" onClick={onChianLihClick}>Chian Lih</Link>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

export default Footer;
