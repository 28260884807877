import main from "../assets/images/hero.png";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

function Hero() {
  return (
    <>
      <Grid container>
        <Grid item xs={12} xl={12} sm={12} md={12} lg={12} sx={{ 
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "center",
          position: "relative",
        }}>
          <Box
            component="img"
            sx={{
              width: "100%",
            }}
            src={main}
          ></Box>
          <Box component="div" sx={{ marginBottom: { xs: "10px", sm: "50px"}, position: "absolute" }}>
            <Typography
              variant="h4"
              noWrap
              sx={{
                textAlign: "center",
                fontFamily: "inherit",
                color: "#FFFFFF",
                textTransform: "capitalize",
              }}
            >
              Chian Lih <span style={{ color: "rgba(253, 160, 160, 1)" }}>&</span> Keen Hoong
            </Typography>
            <Typography
              variant="h2"
              noWrap
              sx={{
                textAlign: "center",
                fontFamily: "inherit",
                color: "#FFFFFF",
              }}
            >
              Tying the Knot
            </Typography>
            <Typography
              variant="h4"
              noWrap
              sx={{
                textAlign: "center",
                fontFamily: "inherit",
                color: "#FFFFFF",
              }}
            >
              8th October 2023
            </Typography>
          </Box>
          
        </Grid>
      </Grid>
    </>
  );
}

export default Hero;
