import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

function Section1() {
  return (
    <>
      <Grid container alignItems="center" justifyContent="center"
        sx={{
          minHeight: {
            xs: "300px",
            sm: "300px",
            md: "500px",
          },
          filter: "drop-shadow(0px 6px 10px rgba(0, 0, 0, 0.10))"
        }}
      >
        <Grid item xs={12} xl={6} sm={12} md={6} lg={6} sx={{ padding: "5%"}}>
          <Typography
            variant="h2"
            textAlign="center"
            sx={{
              fontFamily: "Lovelace",
              textTransform: "lowercase",
            }}
          >
            A love that knows no bounds
          </Typography>
        </Grid>
        <Grid item xs={12} xl={6} sm={12} md={6} lg={6} sx={{ padding: "5%"}}>
          <Typography
            variant="h6"
            textAlign="center"
            paragraph
            sx={{
              fontFamily: "Lovelace",
            }}
          >
            We are taking the next step in our adventure together, and we can’t wait to celebrate with each of you
            <br />
            我們一起踏上新的旅程，迫不及待與你們一起慶祝。
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

export default Section1;
