import './App.css';
import { Divider, ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material';

import Hero from './components/Hero';
import Navbar from './components/Navbar';
import Section1 from './components/Section1';
import Section2 from './components/Section2';
import Section3 from './components/Section3';
import Section4 from './components/Section4';
// import Section5 from './components/Section5';
import Footer from './components/Footer';

const theme = responsiveFontSizes(createTheme());

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Navbar />
        <Divider />
        <Hero />
        <Divider />
        <Section1 />
        <Divider />
        <Section2 />
        <Divider />
        <Section3 />
        <Divider />
        <Section4 />
        <Divider />
        {/* <Section5 />
        <Divider /> */}
        <Footer />
      </ThemeProvider>
    </>
  );
}

export default App;
