import Logo from "../assets/images/logo.png";
import { AppBar, Box, Toolbar } from "@mui/material";

function Navbar() {
  return (
    <AppBar sx={{
      background: "#FFFFFF",
      boxShadow: "0px 6px 10px 0px rgba(0, 0, 0, 0.10)"
    }} color="transparent" position="static">
      <Toolbar>
        <Box
          component="img"
          sx={{ height: '75px', marginLeft: 'auto', marginRight: 'auto' }}
          alt="Logo"
          src={Logo}
        ></Box>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
